<template>
  <main>
    <h3 class="text-center text-lg md:text-2xl font-semibold mb-4">
      Confirm Upload
    </h3>
    <easiPlainTable :data="data" :headers="tableHeaders" :loading="loading" />

    <div class="flex items-center justify-center mt-4">
      <easiButton @click="emit('upload')" class="px-10" :loading="loading"
        >Upload</easiButton
      >
    </div>
  </main>
</template>

<script setup>
import { ref } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
const { formatAmount } = helperFunctions;

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  loading: Boolean,
});

const emit = defineEmits(["upload"]);

const tableHeaders = [
  {
    title: "SN",
    field: "sn",
  },
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Description",
    field: "description",
  },
  {
    title: "Selling Price",
    field: "sellingPrice",
    format: (val) => {
      return val ? formatAmount(val) : "--";
    },
  },
  {
    title: "Cost Price",
    field: "costPrice",
    format: (val) => {
      return val ? formatAmount(val) : "--";
    },
  },
  {
    title: "Quantity",
    field: "quantity",
  },
  {
    title: "Size",
    field: "size",
  },
  {
    title: "Weight(KG)",
    field: "weight",
  },
  {
    title: "Stock Number",
    field: "stockNumber",
  },
];
</script>

<style></style>
