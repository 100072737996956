<template>
  <div
    style="box-shadow: 0px 4px 25px 0px #0000000d"
    class="mx-auto w-full max-w-2xl pb-4 bg-white rounded-2xl overflow-hidden border border-outlined flex flex-col"
  >
    <div class="border-b border-outlined p-4">
      <h3 class="text-center text-lg md:text-2xl font-semibold">
        {{ editMode ? "Edit Item" : "Product Information" }}
      </h3>
    </div>
    <div class="w-full p-4 md:px-10 flex flex-col">
      <easiTextInput
        label="Product Name"
        placeholder="Product Name"
        name="name"
        :disabled="loading"
        v-model="args.name"
        :error="errorRules.name"
      />
      <easiTextArea
        label="Product Description"
        placeholder="Product Description"
        name="description"
        :disabled="loading"
        v-model="args.description"
        :error="errorRules.description"
      />

      <easiSelectInput2
        :options="categoryOptions"
        :value="args.category"
        @update="args.category = $event"
        placeholder="Product Category"
        name="category"
        :disabled="loading"
        class="mt-3"
        addOpt
        addOptText="Add Another"
        hideCheckBox
        @addNew="categoryModal = true"
        :error="errorRules.category"
      ></easiSelectInput2>

      <div class="grid grid-cols-2 gap-4 mt-2">
        <easiTextInput
          format
          label="Sales Price"
          placeholder="Sales Price"
          name="sellingPrice"
          :disabled="loading"
          v-model="args.sellingPrice"
          :error="errorRules.sellingPrice"
        />
        <easiTextInput
          label="Quantity in stock"
          placeholder="Quantity in stock"
          name="quantity"
          :disabled="loading"
          type="number"
          inputmode="number"
          v-model="args.quantity"
          :error="errorRules.quantity"
        />
      </div>

      <hr class="border-outlined mt-6 mb-4" />
      <h2 class="text-xl font-semibold">Optional</h2>

      <div class="grid grid-cols-2 gap-4">
        <easiTextInput
          label="Size"
          placeholder="Size"
          name="size"
          :disabled="loading"
          v-model="args.size"
          :error="errorRules.size"
        />
        <easiTextInput
          label="Colour"
          placeholder="Colour"
          name="colour"
          :disabled="loading"
          v-model="args.colour"
          :error="errorRules.colour"
        />
      </div>
      <div class="grid grid-cols-2 gap-4">
        <easiTextInput
          label="Weight (in kg)"
          placeholder="Weight (in kg)"
          name="weight"
          :disabled="loading"
          type="number"
          inputmode="number"
          v-model="args.weight"
          :error="errorRules.weight"
        />
        <easiTextInput
          label="Stock number"
          placeholder="Stock number"
          name="stockNumber"
          :disabled="loading"
          type="number"
          inputmode="number"
          v-model="args.stockNumber"
          :error="errorRules.stockNumber"
        />
      </div>

      <hr class="border-outlined mt-6 mb-4" />

      <div
        v-if="!showProductImage"
        style="background: #f8f8f8"
        class="flex flex-col gap-3 items-center p-4 rounded-2xl bg-newAsh border border-dashed border-outlineGray"
      >
        <easiUpload
          class="bg-white rounded-full shadow-sm"
          loop
          :showBorder="false"
          loopTitle="Upload product picture"
          @fileUrl="uploadFile = $event"
          :info="'Upload populated form into the system'"
        />

        <span class="text-sm text-dark-500">(JPG, PNG Only) (2mb Maximum)</span>
      </div>
      <div v-else class="flex flex-col gap-2">
        <div class="relative h-28 overflow-hidden rounded-3xl border w-full">
          <img :src="data.image" alt="" class="w-full h-full object-cover" />
        </div>
        <div
          @click="showProductImage = false"
          class="text-sm text-primary flex items-center gap-2 cursor-pointer mx-auto"
        >
          Remove
          <i class="pi pi-times-circle text-xs" />
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4 mt-6">
        <easiButton block variant="outlined" @click="handleClose"
          >Cancel</easiButton
        >
        <easiButton
          :disabled="disabledButton"
          :loading="loading"
          @click="handleSave"
          block
          >Save</easiButton
        >
      </div>
    </div>
  </div>

  <easiAlert
    v-if="isAlert"
    :caption="`Your product has been ${
      editMode ? 'updated' : 'added'
    } successfully`"
  >
    <div class="pb-4 px-6">
      <easiButton size="medium" @click="closeAlert" block>Okay!</easiButton>
    </div>
  </easiAlert>

  <AddEditCategory :isOpen="categoryModal" @close="categoryModal = false" />
</template>

<script setup>
import AddEditCategory from "@/components/BookKeeping/Settings/AddEditCategory.vue";

import { reactive, ref, computed, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { helperFunctions } from "@/composable/helperFunctions";
const { uploadFileToServer } = helperFunctions;
import { useDataStore } from "@/stores/data.js";
import {
  addInventoryItem,
  updateInventoryItem,
} from "@/services/book-keeping/inventory";
import { useToast } from "vue-toastification";

const toast = useToast();

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false,
  },

  data: Object,
});

const store = useDataStore();
const router = useRouter();
const categoryModal = ref(false);

const disabledButton = computed(() => {
  return (
    !args.name.trim() ||
    !args.description.trim() ||
    !args.category ||
    !args.sellingPrice ||
    !args.quantity ||
    !uploadFile
  );
});

const categoryOptions = computed(() => {
  const categories = store.getBookingCategories || [];
  return categories.map((el) => {
    return {
      label: el.name,
      value: el._id,
    };
  });
});

const sizeOptions = [
  {
    label: "Medium",
    value: "Medium",
  },
];

const isAlert = ref(false);
const loading = ref(false);

const uploadFile = ref(null);

const showProductImage = ref(false);
const args = reactive({
  name: "",
  description: "",
  category: "",
  sellingPrice: "",
  currency: "NGN",
  quantity: "",
  size: "",
  colour: "",
  expiryDate: "",
  costPrice: null,
  weight: null,
  unitOfMeasure: "Kg",
  stockNumber: null,
  image: "",
});

const errorRules = reactive({
  name: false,
  description: false,
  category: false,
  sellingPrice: false,
  quantity: false,
  size: false,
  colour: false,
  expiryDate: false,
  costPrice: false,
  weight: false,
  stockNumber: false,
  image: false,
});

onMounted(() => {
  //Asign values
  if (props.data) {
    Object.keys(args).forEach((key) => {
      args[key] = props.data[key];
    });

    showProductImage.value = true;

    console.log(args);
  }
});

////// METHODS /////
const validateInput = () => {};

const clearFields = () => {
  Object.keys(args).map((key) => {
    args[key] = "";
  });

  args.weight = null;
  args.costPrice = null;
  args.stockNumber = null;
  args.currency = "NGN";
  args.unitOfMeasure = "KG";
  uploadFile.value = null;

  //clear the errors
  Object.keys(errorRules).map((key) => {
    errorRules[key] = false;
  });
};

const handleClose = () => {
  clearFields();
  router.go(-1);
};

const closeAlert = () => {
  handleClose();
  isAlert.value = false;
};

const handleSave = async () => {
  try {
    loading.value = true;

    if (uploadFile.value) {
      args.image = await uploadFileToServer(uploadFile.value);
      if (!args.image) return;
    }

    const payload = { ...args };

    const intValues = ["quantity", "stockNumber", "weight"];
    intValues.forEach((el) => {
      if (payload[el]) {
        payload[el] = Number(payload[el]);
      }
    });

    const res = props.editMode
      ? await updateInventoryItem(props.data._id, payload)
      : await addInventoryItem(payload);

    if (res.success) {
      isAlert.value = true;
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};
</script>

<style></style>
