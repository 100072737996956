<template>
  <main class="px-2 md:px-4 py-6 flex flex-col gap-4">
    <h4
      class="capitalize md:uppercase md:text-sm text-xs font-medium text-headerText"
    >
      Book Keeping / Inventory / Add
    </h4>
    <easiBackButton />

    <easiTab class="max-w-xl" :tabItems="tabs" v-model:active="activeTab" />

    <div
      class="relative overflow-hidden flex mx-auto items-center justify-between gap-4 w-44 my-4"
    >
      <div
        :class="
          activeTab == 'single add'
            ? 'bg-secondary text-white'
            : 'bg-white text-dark-700 border-dark-100'
        "
        class="w-7 h-7 text-xs rounded-full border flex items-center justify-center z-10"
      >
        1
      </div>
      <div
        :class="
          activeTab == 'bulk add'
            ? 'bg-secondary text-white'
            : 'bg-white text-dark-700 border-dark-100'
        "
        class="w-7 h-7 text-xs rounded-full border flex items-center justify-center z-10"
      >
        2
      </div>
      <hr class="border-dark-100 absolute left-0 w-full" />
    </div>

    <div>
      <AddEditInventory v-if="activeTab === 'single add'" />
      <BulkAdd v-if="activeTab === 'bulk add'" />
    </div>
  </main>
</template>

<script setup>
import BulkAdd from "@/components/BookKeeping/Inventory/BulkAdd.vue";
import AddEditInventory from "@/components/BookKeeping/Inventory/AddEditInventory.vue";

import { ref } from "vue";

const activeTab = ref("single add");
const tabs = ref(["single add", "bulk add"]);
</script>

<style></style>
