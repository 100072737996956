<template>
  <div
    style="box-shadow: 0px 4px 25px 0px #0000000d"
    :class="[preview ? '' : 'max-w-xl']"
    class="mx-auto w-full p-6 md:p-8 bg-white rounded-2xl overflow-hidden border border-outlined"
  >
    <div v-if="!preview">
      <div v-if="!analyzing" class="gap-6 flex flex-col">
        <h3 class="text-center text-lg md:text-2xl font-semibold">
          Upload Items
        </h3>

        <div
          class="p-4 bg-grayLight border border-newAsh rounded-xl text-sm flex items-center gap-4 justify-between"
        >
          <span
            >Download our CSV template, populate it and upload to the
            system</span
          >

          <easiButton
            @click="handleDownload"
            variant="text"
            size="small"
            class="bg-white gap-2"
            :loading="loading"
          >
            Download
            <i class="pi pi-download text-sm" />
          </easiButton>
        </div>
        <div
          class="p-4 bg-grayLight border border-newAsh rounded-xl text-sm flex items-center gap-4 justify-between"
        >
          <span>Upload the filled template to the system here</span>
          <easiUpload
            class="bg-white rounded-full shadow-sm"
            loop
            :showBorder="false"
            @fileUrl="handleFileUpload"
            :info="'Upload populated form into the system'"
          />
        </div>
        <easiButton
          :disabled="!jsonData"
          @click="handleSave"
          class="px-20 mx-auto"
          >Save</easiButton
        >
      </div>

      <!-- ///// Analyzing ///// -->

      <div v-else class="flex flex-col gap-2 items-center">
        <h3 class="text-center text-lg md:text-2xl font-semibold">
          Analysing Data
        </h3>

        <easiLoader class="w-48 mx-auto rounded-full" />
        <span class="text-xs">
          <strong> Hold</strong> on this won’t take long
        </span>
      </div>
    </div>

    <div v-else>
      <ConfirmUpload :data="jsonData" :loading="loading" @upload="submit" />
    </div>
  </div>

  <easiAlert
    v-if="isAlert"
    :caption="`Your products has been added successfully`"
  >
    <div class="pb-4 px-6">
      <easiButton size="medium" @click="isAlert = false" block
        >Okay!</easiButton
      >
    </div>
  </easiAlert>
</template>

<script setup>
import ConfirmUpload from "./ConfirmUpload.vue";

import { ref } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import * as xlsx from "xlsx";
import { addInventoryItem } from "@/services/book-keeping/inventory";

const { downloadExcelFromBase64 } = helperFunctions;
const toast = useToast();
const store = useDataStore();
const { query } = store;

const isAlert = ref(false);
const analyzing = ref(false);
const loading = ref(false);
const preview = ref(false);

const jsonData = ref(null);

const transformDataKeys = (json) => {
  if (!json) return [];
  let arr = [];
  arr = json.map((el) => {
    let item = {};
    Object.keys(el).map((key) => {
      if (key) {
        let newKey = key.toLowerCase();

        if (newKey.includes(" ")) {
          let [first, second] = key.split(" ");
          first = first.toLowerCase();
          newKey = first + second;
        }

        if (newKey == "weight(kg)") {
          item["weight"] = el[key];
        } else {
          item[newKey] = el[key];
        }
      }
    });
    return item;
  });
  return arr;
};

const handleFileUpload = (file) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = xlsx.read(data, { type: "array" });
    const firstSheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[firstSheetName];
    const json = xlsx.utils.sheet_to_json(sheet);
    jsonData.value = transformDataKeys(json);
  };

  reader.readAsArrayBuffer(file);
};

const handleDownload = async () => {
  try {
    loading.value = true;
    let res = await query({
      endpoint: "DownloadExcelTemplate",
      payload: { type: "addInventories" },
      service: "BOOKKEEPING",
    });

    if (res) {
      downloadExcelFromBase64(res, "inventory-sheet");
    } else {
      toast.error("Something went wrong");
    }
  } catch (error) {
    toast.error(error.message);
    console.log(error);
  } finally {
    loading.value = false;
  }
};

const handleSave = () => {
  if (!jsonData.value) return;

  analyzing.value = true;
  setTimeout(() => {
    analyzing.value = false;
    preview.value = true;
  }, 2000);
};

const submit = async () => {
  try {
    loading.value = true;

    //remove sn from the data
    const payload = jsonData.value.map((el) => {
      let newItem = { ...el };
      delete newItem.sn;
      return newItem;
    });

    const res = await addInventoryItem(payload, true);
    console.log(res);
    if (res.success) {
      preview.value = false;
      isAlert.value = true;
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    console.log(error.message);
  } finally {
    loading.value = false;
  }
};
</script>

<style></style>
