<template>
  <easiModal
    v-if="isOpen"
    @close="handleClose"
    :isHeader="false"
    :isBorder="false"
    center
    persistence
    rounded="3xl"
  >
    <h1
      class="capitalize text-center text-dark-900 text-2xl font-bold pt-6 pb-2"
    >
      {{ editMode ? "Edit" : "Add" }} Category
    </h1>

    <div class="flex flex-col w-full px-4 gap-6 pb-3 md:px-6">
      <easiTextInput
        label="Category"
        :disabled="loading"
        placeholder="Category"
        v-model="categoryInput"
        :error="isError"
      />

      <easiButton :loading="loading" block @click="handleSubmit"
        >Save</easiButton
      >
    </div>
  </easiModal>

  <!-- //////  Alert Modal ////// -->

  <easiAlert
    v-if="isAlert"
    :title="`${editMode ? 'Edit' : 'Add'} category`"
    :caption="`Category ${editMode ? 'edited' : 'added'} successfully!`"
  >
    <div class="pb-4 px-6">
      <easiButton size="medium" @click="isAlert = false" block
        >Dismiss!</easiButton
      >
    </div>
  </easiAlert>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { addCategory, updateCategory } from "@/services/book-keeping/settings";
import { useToast } from "vue-toastification";

const toast = useToast();

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  data: [Object, null],
  editMode: {
    type: Boolean,
    default: false,
  },
});

const watchIsOpen = computed(() => props.isOpen);
const emit = defineEmits(["close"]);

const isAlert = ref(false);
const loading = ref(false);

const categoryInput = ref("");
const isError = ref(false);

watch(watchIsOpen, () => {
  const { isOpen, data } = props;
  if (isOpen && data) {
    categoryInput.value = data.name;
  }
});

const handleClose = () => {
  categoryInput.value = "";
  isError.value = false;
  emit("close");
};

const handleSubmit = async () => {
  isError.value = false;
  if (!categoryInput.value.trim()) {
    isError.value = "Category is required";
    return;
  }

  try {
    loading.value = true;

    // if editmode is true
    let payload;
    if (props.editMode) {
      payload = {
        id: props.data._id,
        name: categoryInput.value,
      };
    }

    const res = props.editMode
      ? await updateCategory(payload)
      : await addCategory(categoryInput.value);

    if (res.success) {
      handleClose();
      isAlert.value = true;
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    toast.error(error.message);
    console.log(error);
  } finally {
    loading.value = false;
  }
};
</script>

<style></style>
